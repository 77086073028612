import type { FireStoreConfigType } from "./types";

// this will ensure we can add undefined to typings at a later date, typecheck the config, but return the const typings
export const createInitialConfig = <T extends FireStoreConfigType>(initialConfig: T) => initialConfig;

export const initialConfig = createInitialConfig({
  configSet: {
    appUpdateVersion: "1.0.0",
    biometricsCheckSeconds: 300,
    analyticsSessionTimeSeconds: 1800,
    disableRegister: false,
    inviteCommissionPercent: 20,
    inviteVoucherEuroValue: 10,
    marketCapCoinOrder: [
      "BTC",
      "ETH",
      "BNB",
      "DOGE",
      "ADA",
      "XRP",
      "DOT",
      "BCH",
      "LTC",
      "LINK",
      "XLM",
      "VET",
      "ETC",
      "TRX",
      "EOS",
      "XMR",
      "ATOM",
      "XTZ",
      "ALGO",
      "DASH",
      "WAVES",
      "ZEC",
      "QTUM",
      "BAT",
      "SNX",
      "ICX",
      "OMG",
      "NANO",
      "LSK",
      "KNC",
    ],
    maxCoinOrderLimit: 200000,
    marketRefreshTimeSeconds: 15,
    minBuyAmount: 1,
    minSellAmount: 1,
    minGraphDataPoints: 6,
    offersAPIfetchCount: 100,
    queryCacheTimeSeconds: 90,
    rateInTrustPilot: false,
    riskDialogueScreenDelayDays: 30,
    newUserDelaySeconds: 2,
    otherCoinsBucketSizePercent: 5,
    zenDeskDepartment: "BLOX",
    showNotificationsFromVersion: "3.11.0",
    showOnboardingFromVersion: "3.14.1",
    showStakingFromVersion: "4.1.0",
    showNewsFromVersion: "4.1.0",
    showBiometricsFromVersion: "4.1.0",
    showDCAFromVersion: "5.1.0",
    showTermsFromVersion: "4.1.0",
    maxSignUpsPerDevice: 5,
    promotions: [],
    maintenanceMessage: {
      en: "",
    },
    maintenanceKeepInformedButton: false,
    showOutageContentInApp: false,
    feedbackFormUrl: {
      en: "https://forms.gle/q8uRp9XP5egPbnzy6",
    },
    coinRestrictions: [
      {
        shortNames: ["QTUM", "DASH", "ATOM", "BAT", "ETC", "LSK", "SNX", "ICX", "KNC"],
        buyAmount: 5000,
        sellAmount: 5000,
      },
    ],
    slippageBuyWarningPercentage: 0, // e.g. 1.5
    slippageSellWarningPercentage: 0, // e.g. 1.5
    spreadWarningPercentage: 2.5,
    onboardingBusinessFieldLengths: {
      cocnumber: 20,
      company: 100,
    },
    disableDocTypesForCountries: {
      DRIVER_LICENSE: ["ES"],
    },
    disableBuyForCoins: [],
    disableSellForCoins: [],
    referralVoucherValidDays: 30,
    newsCategories: {},
    newsApiUrl: "https://nieuws.btcdirect.eu/graphql",
    coinNotifications: [],
    showChatFromVersion: "4.2.2",
    firstPartyPixel: "https://pxl.weareblox.com/blox-pixel-event",
    cryptoTransferExcludeCoins: [],
    cryptoTransferComingSoonCoins: [],
    cryptoTransferExcludeProviders: [],
    cryptoTransferPopularProviders: [],
    showCryptoTransferFromVersion: "4.2.6",
    cryptoTransferWebTestUsers: [],
    cryptoTransferCoinMessages: [],
    cryptoTransferChainWarningCoins: [],
    blockchainExplorerUrl: `https://mempool.space/tx/{txHash}`,
    blockchainExplorerUrlPerCoin: null,
    showCryptoTransferNewBanner: false,
    chainWarningMoreInfoUrl: {
      en: "https://weareblox.com/nl-nl/let-op-voordat-je-stort#English",
      nl: "https://weareblox.com/nl-nl/let-op-voordat-je-stort",
    },
    withdrawalLimit: 750000,
    stakingHelpVideoUrl: {},
    lendingHelpVideoUrl: {},
    lendingExplanationButtonUrl: {
      en: "",
    },
    lendingRisksButtonUrl: {
      en: "",
    },
    lendingLimitCoins: [],
    showBoughtEarnCoinCTABanner: false,
    disableLendingForBE: false,
    showLendingNewBanner: false,
    showLendingInfoBanner: false,
    showStakingInfoBanner: false,
    stakingInfoUrl: {
      en: "",
    },
    lendingComingSoonCoins: [],
    stakingComingSoonCoins: [],
    sellCoinScreenEuroDefault: false,
    showCoinStatisticsFromVersion: "4.7.0",
    newMarketCoins: [],
    memesMarketCoins: [],
    aiMarketCoins: [],
    gamingMarketCoins: [],
    quickActions: {},
    maximumAmountFaqId: "12394914822033",
    showRewardsBySellPrice: false,
    newDepositMethods: { dca: false },
    depositModalButtons: {
      cryptoTransfer: false,
      dca: false,
    },
    errorCodes: {},
    contactOpenTimes: [
      { label: { en: "Monday to Friday" }, value: "09:00 - 22:00" },
      { label: { en: "Saturday to Sunday" }, value: "10:00 - 18:00" },
    ],
    functionNotAvailableKeepMePosted: {
      buy: false,
      sell: false,
      deposit: false,
      withdraw: false,
    },
    inviteQrCodeUrl: "https://weareblox.com/invite",
    showCMChatbotFromVersion: "6.1.0",
    removeAccountReasons: [
      { reason: "missingFeatures", label: { en: "I'm missing features in the app" } },
      { reason: "highFee", label: { en: "I think BLOX is too expensive" } },
      { reason: "noProfit", label: { en: "I don't make any profit" } },
      { reason: "competitor", label: { en: "I've switched to a different platform" } },
      { reason: "quitCrypto", label: { en: "I no longer trade cryptos" } },
      { reason: "dontAcceptConditions", label: { en: "I do not accept terms and conditions" } },
      { reason: "other", label: { en: "Other" } },
    ],
    disableVisa: {
      activateDisableVisa: true,
      cardsLabel: { en: "Cards (Temp. no VISA)", nl: "Cards (Tijd. geen VISA)" },
    },
  },
  features: {
    chat: false,
    feedbackMail: false,
    overallResults: true,
    telephone: false,
    depositNewBankAccountScreen: false,
    accountRemoval: false,
    cfpCheck: false,
    addVoucher: false,
    notifications: true,
    feedbackForm: false,
    priceAlerts: true,
    animatedPriceToggle: false,
    webDashboard: false,
    termsConditions: false,
    cryptoTransfer: false,
    dcaWeb: false,
    screenshotShareVault: false,
    screenshotShareCoinlisting: false,
    appEnableFirebaseAnalytics: false,
  },
} satisfies FireStoreConfigType);
